import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import gsap from 'gsap';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { FontLoader } from 'three/src/loaders/FontLoader.js';
import style from '../src/style.css';                                                                     
         



/****************************** VARIABLES ****************************/
let minview = {value:0}
let maxview = {value:400}
let mvmvideo={value:0};
let mvmweb={value:0};
let mvmabout={value:0};
let noneanimation=0;
let chantier=0;
let sallecine=0;
let travel=0;
let lookcamx={value:0};
let lookcamy={value:13};
let lookcamz={value:180};
let poscamx={value:0};
let poscamy={value:0};
let fogR={value:145};
let fogG={value:236};
let fogB={value:238};
let lookatdepart=0;
const barre = document.querySelector(".bar");
const buttonstart = document.querySelector(".button-start");
const buttonback = document.querySelector(".button-back");
const controlsound = document.querySelector("#sound-control");
const orientation = document.querySelector("#rotate-phone");
const loadingText = document.querySelector("#text-loader");
const loadingBar = document.querySelector(".loading-bar");
let woosh = document.getElementById("woosh");
let audio = document.getElementById("son");
let audiob = document.getElementById("sonb");
let neon = document.getElementById("neon");
let distortion = document.getElementById("distortion");
let distortion_entre = document.getElementById("distortion_entre");
let glitch = document.getElementById("glitch");
let sortienuage=0;
let activesound=1;
var video = document.getElementById('video');













/****************************** START SCENE ****************************/
//const fogColor = new THREE.Color(0x91ECEE );
const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()
const fogColor = new THREE.Color("rgb("+fogR.value+", "+fogG.value+", "+fogB.value+")");
scene.background=fogColor;
scene.fog = new THREE.Fog(fogColor, 0, maxview.value);

const loadingManager = new THREE.LoadingManager(
    ()=>{
        window.setTimeout(() =>{
            loadingBar.classList.add('ended')
            loadingBar.style.transform = ''
            document.querySelector('#text-loader').classList.add('endload')
            setTimeout(function(){  document.querySelector('#loader').classList.add('endload')}, 1000);
            setTimeout(function(){  document.querySelector("#loader").style.display = "none"; }, 2300);
        }, 500)
    },
    (itemUrl, itemsLoaded, itemsTotal)=>{
        const progressRatio = itemsLoaded / itemsTotal
        loadingText.innerHTML = (progressRatio*100).toFixed(0)+'%'
        loadingBar.style.transform = `scaleX(${progressRatio})`
    },
)


















/****************************** TEXTURES ****************************/
function loadcine(){
    setTimeout(function(){  
        const videoTexture = new THREE.VideoTexture(video);
        const videoMaterial =  new THREE.MeshBasicMaterial( {map: videoTexture,  toneMapped: false} );
        const screen = new THREE.PlaneGeometry(28,13);
        const videoScreen = new THREE.Mesh(screen, videoMaterial);
        videoScreen.rotation.y = Math.PI
        videoScreen.position.set(-4.8,5.5,120)
        scene.add(videoScreen);
        video.volume=0;
     }, 50);
    }
const loader = new THREE.TextureLoader(loadingManager);
loader.load("models/smoke.png", function(texture){
   const cloudGeo = new THREE.PlaneBufferGeometry(80,80);
   const cloudMaterial = new THREE.MeshLambertMaterial({
     map:texture,
     transparent: true
   });

   for(let p=0; p<5; p++) {
       let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
       cloud.position.set(
         Math.random()*100 -50,
         Math.random()*75 -25,
         Math.random()*-400 -80
       );
       cloud.rotation.x=Math.PI
       cloud.rotation.y = -0.12;
       cloud.rotation.z = Math.random()*2*Math.PI;
       cloud.material.opacity = 0.10;
       scene.add(cloud);
     }
});
  const loader1 = new THREE.TextureLoader(loadingManager);
  loader1.load("models/ordi1.JPG", function(texture2){
     const ordiGeo = new THREE.PlaneBufferGeometry(2.3,1.3);
     const ordiMaterial = new THREE.MeshLambertMaterial({
       map:texture2,
       transparent: true
     });
     let ordi1 = new THREE.Mesh(ordiGeo, ordiMaterial);
    ordi1.rotation.set(Math.PI,-Math.PI/2,Math.PI)
    ordi1.position.set(-59.2, 55, 158.8);
    ordi1.name = "spa"
    scene.add(ordi1);
  });
  loader1.load("models/ordi2.jpg", function(texture3){
    const ordiGeo = new THREE.PlaneBufferGeometry(2.3,1.3);
    const ordiMaterial = new THREE.MeshLambertMaterial({
      map:texture3,
      transparent: true
    });
    let ordi2 = new THREE.Mesh(ordiGeo, ordiMaterial);
    ordi2.rotation.set(Math.PI,-Math.PI/2,Math.PI)
   ordi2.position.set(-59.2, 55, 152.7);
   ordi2.name = "halloween"
   scene.add(ordi2) ;
 });
 loader1.load("models/ordi3.jpg", function(texture4){
    const ordiGeo = new THREE.PlaneBufferGeometry(2.3,1.3);
    const ordiMaterial = new THREE.MeshLambertMaterial({
      map:texture4,
      transparent: true
    });
    let ordi3 = new THREE.Mesh(ordiGeo, ordiMaterial);
    ordi3.rotation.set(Math.PI,-Math.PI/2,Math.PI)
    ordi3.position.set(-59.2, 55, 162.5);
    ordi3.name = "champagne"
   scene.add(ordi3) ;
 });
















/****************************** ELEMENTS BLENDER ****************************/
const gltfLoader = new GLTFLoader(loadingManager)
new GLTFLoader(loadingManager)
.setPath( 'models/' )
.setDRACOLoader( new DRACOLoader().setDecoderPath( 'draco/gltf/' ) )
.load( 'city.glb', function ( maison ) {
  scene.add(maison.scene)
  const instagram = maison.scene.children.find((child) => child.name === 'instagram')
  const tiktok = maison.scene.children.find((child) => child.name === 'tiktok')
  const video1 = maison.scene.children.find((child) => child.name === 'video1')
  const video3 = maison.scene.children.find((child) => child.name === 'video3')
  const video2 = maison.scene.children.find((child) => child.name === 'video2')
  const grue = maison.scene.children.find((child) => child.name === 'grue')
  const zeplin = maison.scene.children.find((child) => child.name === 'zeplin')
  const elice = maison.scene.children.find((child) => child.name === 'elice')
  const serv1 = maison.scene.children.find((child) => child.name === 'server1')
  const serv2 = maison.scene.children.find((child) => child.name === 'server2')
  const serv3 = maison.scene.children.find((child) => child.name === 'server3')
  const book = maison.scene.children.find((child) => child.name === 'studytext')
  const poutre = maison.scene.children.find((child) => child.name === 'poutre')
  const rouegauche = maison.scene.children.find((child) => child.name === 'rouegauche')
  const rouedroite = maison.scene.children.find((child) => child.name === 'rouedroite')
  serv1.material.transparent = true
  serv2.material.transparent = true
  serv3.material.transparent = true
  tiktok.material.transparent = true
  instagram.material.transparent = true
  video1.material.transparent = true
  video2.material.transparent = true
  video3.material.transparent = true
  book.material.transparent = true

const geometryhitboxinsta = new THREE.PlaneBufferGeometry(8,2);
const  hitboxinsta = new THREE.Mesh(geometryhitboxinsta,material);
hitboxinsta.visible= false;
hitboxinsta.position.set(-30.3,27.2,253);
hitboxinsta.rotation.set(0,Math.PI*0.78,0)
hitboxinsta.name = "boxinsta"


const geometryhitboxfilm1 = new THREE.PlaneBufferGeometry(6.5,1.7);
const  hitboxfilm1 = new THREE.Mesh(geometryhitboxfilm1,material);
hitboxfilm1.visible= false;
hitboxfilm1.position.set(5.4,11.6,70.5);
hitboxfilm1.rotation.set(0,Math.PI*1.15,0)
hitboxfilm1.name = "boxfilm1"

const geometryhitboxfilm2 = new THREE.PlaneBufferGeometry(6.5,1.7);
const  hitboxfilm2 = new THREE.Mesh(geometryhitboxfilm2,material);
hitboxfilm2.visible= false;
hitboxfilm2.position.set(5.7,8.8,70.5);
hitboxfilm2.rotation.set(0,Math.PI*1.15,0)
hitboxfilm2.name = "boxfilm2"

const geometryhitboxfilm3 = new THREE.PlaneBufferGeometry(6,1.7);
const  hitboxfilm3 = new THREE.Mesh(geometryhitboxfilm3,material);
hitboxfilm3.visible= false;
hitboxfilm3.position.set(-19.4,9.7,62.2);
hitboxfilm3.rotation.set(0,-(Math.PI*1.35),0)
hitboxfilm3.name = "boxfilm3"                        

const geometryhitboxtiktok = new THREE.PlaneBufferGeometry(8,2);
const  hitboxtiktok = new THREE.Mesh(geometryhitboxtiktok,material);
hitboxtiktok.visible= false;
hitboxtiktok.position.set(-22.9,27.2,258);
hitboxtiktok.rotation.set(0,Math.PI*0.78,0)
hitboxtiktok.name = "boxtiktok"

const geometryhitboxstudy = new THREE.PlaneBufferGeometry(2.7,3.8);
const  hitboxstudy = new THREE.Mesh(geometryhitboxstudy,material);
hitboxstudy.visible= false;
hitboxstudy.position.set(-7.8,29.2,260.2);
hitboxstudy.rotation.set(0,Math.PI*0.78,0)
hitboxstudy.name = "boxstudy"
scene.add(hitboxtiktok,hitboxstudy,hitboxinsta,hitboxfilm1,hitboxfilm2,hitboxfilm3);


serv()
function serv(){
    gsap.to(serv1.material, {duration: 0.4, opacity: 0}) 
    gsap.to(serv2.material, {duration: 0.4, opacity: 0}) 
    gsap.to(serv3.material, {duration: 0.8, opacity: 1}) 
    gsap.to(serv2.material, {duration: 0.4, delay:1.2, opacity: 1}) 
    gsap.to(serv3.material, {duration: 0.5, delay:1.7, opacity: 0}) 
    gsap.to(serv2.material, {duration: 0.4, delay:2, opacity: 0}) 
    gsap.to(serv2.material, {duration: 0.3, delay:2.6, opacity: 1}) 
    gsap.to(serv2.material, {duration: 0.2, delay:3, opacity: 0}) 
    gsap.to(serv1.material, {duration: 0.3, delay:3, opacity: 1}) 
    gsap.to(serv3.material, {duration: 0.2, delay:4, opacity: 0}) 
    gsap.to(serv1.material, {duration: 0.3, delay:4.5, opacity: 0}) 
    gsap.to(serv1.material, {duration: 0.2, delay:4.8, opacity: 1}) 
    gsap.to(serv2.material, {duration: 0.3, delay:6, opacity: 1}) 
    gsap.to(serv3.material, {duration: 0.2, delay:6.5, opacity: 1}) 
    setTimeout(function(){  serv() }, 6700);
}


rail()
function rail(){
    gsap.to(grue.position, {duration: 12, x: -4, ease:'Sine.easeInOut'})
    gsap.to(grue.position, {duration: 12, delay:13, x: -7, ease:'Sine.easeInOut'})
    setTimeout(function(){  rail() }, 30000);
}


                                
rouedroite.rotation.x=-0.4
rouegauche.rotation.x=0.4
fill()
function fill(){
    gsap.to(poutre.position, {duration: 5, y: 64.5, ease:'Sine.easeInOut'})
    gsap.to(rouedroite.rotation, {duration: 5, x: 0.4, ease:'Sine.easeInOut'})
    gsap.to(rouegauche.rotation, {duration: 5, x: -0.4, ease:'Sine.easeInOut'})
    gsap.to(poutre.position, {duration: 5, delay:7, y: 65, ease:'Sine.easeInOut'})
    gsap.to(rouedroite.rotation, {duration: 5, delay:7, x: -0.4, ease:'Sine.easeInOut'})
    gsap.to(rouegauche.rotation, {duration: 5, delay:7, x: 0.4, ease:'Sine.easeInOut'})
    setTimeout(function(){  fill() }, 15000);
}


vol()
function vol(){
    gsap.to(zeplin.position, {duration: 34, x: -100,z: 330,y:51, ease:'Sine.easeInOut'})
    gsap.to(elice.position, {duration: 34, x: -84,z: 323,y:41.6, ease:'Sine.easeInOut'})
    gsap.to(elice.rotation, {duration: 34, x: 1000})
    gsap.to(zeplin.position, {duration: 6, delay:34, x: 0,z: 400,y:10, ease:'Sine.easeInOut'})
    gsap.to(elice.position, {duration: 6, delay:34, x: 16,z: 393,y:0.6, ease:'Sine.easeInOut'})
    gsap.to(elice.rotation, {duration: 34, delay:34, x: 0})
    gsap.to(zeplin.position, {duration: 8, delay:40,x: 100, z: 240, y:45, ease:'Sine.easeInOut'})
    gsap.to(elice.position, {duration: 8, delay:40,x: 116,z: 233,y:35.6, ease:'Sine.easeInOut'})
    setTimeout(function(){  vol() }, 48000);
}



 window.addEventListener('mousemove', (event) =>
 {
     mouse.x = event.clientX / sizes.width * 2 - 1
     mouse.y = - (event.clientY / sizes.height) * 2 + 1
     raycaster.setFromCamera(mouse, camera)
     const found = raycaster.intersectObjects(scene.children)

     if(found.find(element=>element.object.name==='boxinsta')){
        gsap.to(tiktok.material, {duration: 0.6, opacity: 0.4, ease:'expo.Out'})
        document.body.style.cursor = 'pointer'
     }
     if(found.find(element=>element.object.name==='boxtiktok')){
        gsap.to(instagram.material, {duration: 0.6, opacity: 0.4, ease:'expo.Out'})
        document.body.style.cursor = 'pointer'
     }
     if(found.find(element=>element.object.name==='boxfilm1' && travel==0)){
        gsap.to(video1.material, {duration: 0.6, opacity: 0.4, ease:'expo.Out'})
        document.body.style.cursor = 'pointer'
     }
     if(found.find(element=>element.object.name==='boxfilm2' && travel==0)){
        gsap.to(video2.material, {duration: 0.6, opacity: 0.4, ease:'expo.Out'})
        document.body.style.cursor = 'pointer'
     }
     if(found.find(element=>element.object.name==='boxfilm3' && travel==0)){
        gsap.to(video3.material, {duration: 0.6, opacity: 0.4, ease:'expo.Out'})
        document.body.style.cursor = 'pointer'
     }
     if(found.find(element=>element.object.name==='boxstudy')){
        gsap.to(book.material, {duration: 0.6, opacity: 0.4, ease:'expo.Out'})
        document.body.style.cursor = 'pointer'
     }
     if(!(found.find(element=>element.object.name==='boxtiktok')) && !(found.find(element=>element.object.name==='boxinsta'))){
        gsap.to(tiktok.material, {duration: 0.6, opacity: 1, ease:'expo.Out'}) 
        gsap.to(instagram.material, {duration: 0.6, opacity: 1, ease:'expo.Out'})
    }
    if(!(found.find(element=>element.object.name==='boxfilm1'))){
        gsap.to(video1.material, {duration: 0.6, opacity: 0.9, ease:'expo.Out'})
    }
    if(!(found.find(element=>element.object.name==='boxfilm2'))){
        gsap.to(video2.material, {duration: 0.6, opacity: 0.9, ease:'expo.Out'})
    }
    if(!(found.find(element=>element.object.name==='boxfilm3'))){
        gsap.to(video3.material, {duration: 0.6, opacity: 0.9, ease:'expo.Out'})
    }
    if(!(found.find(element=>element.object.name==='boxstudy'))){
        gsap.to(book.material, {duration: 0.6, opacity: 0.9, ease:'expo.Out'})
    }
 })

                          
 window.addEventListener('click', () =>{
    mouse.x = (event.clientX / sizes.width) * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1
    raycaster.setFromCamera(mouse, camera)
    const found = raycaster.intersectObjects(scene.children)
    if(found.find(element=>element.object.name==='boxinsta') && sortienuage==1){
        window.open("https://www.instagram.com/mathio___/", '_blank');
    }
    if(found.find(element=>element.object.name==='boxtiktok') && sortienuage==1){    
        window.open("https://www.tiktok.com/@m_poug", '_blank');
    }
    if(found.find(element=>element.object.name==='boxstudy') && sortienuage==1){  
        gsap.to(camera.position, { duration: 4,  x: -13, y: 62, z: 276, ease: 'expo.inOut' })
        woosh.volume=0.6;
        setTimeout(function(){woosh.play();}, 930);
       gsap.to(poutre.rotation, { delay:2, duration: 3,  y: 1.99, x:-0.39, z:0.31})
        gsap.to(maxview, {duration: 4, delay:1, value: 75,  onUpdate: function() {
          scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
          const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)
        },
        });
        chantier=1;
    }
    if(found.find(element=>element.object.name==='boxfilm1') && sortienuage==1){  
        video.src = 'models/video1.mp4';
        sallecine=1;
        travel=1;
      setTimeout(function(){ video.play() }, 5000);  
      gsap.to(video, {duration: 2, delay: 5 ,volume: 0.8});
      gsap.to(mvmvideo, {duration: 9 ,value: 0.93, delay:0, ease: CustomEase.create("custom", "M0,0 C0.2,0 0.236,0.255 0.292,0.494 0.397,0.95 0.504,1 1,1 "), onUpdate: function() {updateCameravideo()},});
      gsap.to(audio, {duration: 3, delay: 4 ,volume: 0});
      gsap.to(minview, {duration: 4, delay: 3.8 ,value: 30,  onUpdate: function() {
        scene.fog = new THREE.Fog(fogColor, minview.value, maxview.value);
        },
      });
      setTimeout(function(){ travel=0 }, 9000);
    }
    if(found.find(element=>element.object.name==='boxfilm2') && sortienuage==1){  
        video.src = 'models/video2.mp4';
        sallecine=1;
        travel=1;
      setTimeout(function(){ video.play() }, 5000);  
      gsap.to(video, {duration: 2, delay: 5 ,volume: 0.8});
      gsap.to(mvmvideo, {duration: 9 ,value: 0.93, delay:0, ease: CustomEase.create("custom", "M0,0 C0.2,0 0.236,0.255 0.292,0.494 0.397,0.95 0.504,1 1,1 "), onUpdate: function() {updateCameravideo()},});
      gsap.to(audio, {duration: 3, delay: 4 ,volume: 0});
      gsap.to(minview, {duration: 4, delay: 3.8 ,value: 30,  onUpdate: function() {
        scene.fog = new THREE.Fog(fogColor, minview.value, maxview.value);
        },
      });
      setTimeout(function(){ travel=0 }, 9000);
    }
    if(found.find(element=>element.object.name==='boxfilm3') && sortienuage==1){  
        video.src = 'models/video3.mp4';
        sallecine=1;
        travel=1;
      setTimeout(function(){ video.play() }, 5000);  
      gsap.to(video, {duration: 2, delay: 5 ,volume: 0.8});
      gsap.to(mvmvideo, {duration: 9 ,value: 0.93, delay:0, ease: CustomEase.create("custom", "M0,0 C0.2,0 0.236,0.255 0.292,0.494 0.397,0.95 0.504,1 1,1 "), onUpdate: function() {updateCameravideo()},});
      gsap.to(audio, {duration: 3, delay: 4 ,volume: 0});
      gsap.to(minview, {duration: 4, delay: 3.8 ,value: 30,  onUpdate: function() {
        scene.fog = new THREE.Fog(fogColor, minview.value, maxview.value);
        },
      });
      setTimeout(function(){ travel=0 }, 9000);
    }
 })
});














 














/****************************** LUMIERES ****************************/

const sun = new THREE.AmbientLight(0xFFFFF)    
scene.add(sun)


const light1 = new THREE.AmbientLight(0xffffff, 0.3);
light1.position.set(-59.2, 57, 158.8);
scene.add(light1)                 











/****************************** TEXTES ****************************/
 const fontLoader = new FontLoader(loadingManager)
 fontLoader.load(
     '/font/NEON.json',
     (font) =>
     {
        const textvideoG = new THREE.TextGeometry(
            'VIDEO',
            {
                font: font,
                size: 0.9,
                height: 0.01,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5,
            }
        )
        const textwebG = new THREE.TextGeometry(
            'WEB',
            {
                font: font,
                size: 0.9,
                height: 0.01,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5,
            }
        )
        const textaboutG = new THREE.TextGeometry(
            'ABOUT',
            {
                font: font,
                size: 0.9,
                height: 0.01,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5,
            }
        )
        const textMaterialw = new THREE.MeshBasicMaterial()
        const textMaterialv = new THREE.MeshBasicMaterial()
        const textMateriala = new THREE.MeshBasicMaterial()
        const textvideo = new THREE.Mesh(textvideoG, textMaterialv)
        const textweb = new THREE.Mesh(textwebG, textMaterialw)
        const textabout = new THREE.Mesh(textaboutG, textMateriala)
        textvideo.material.transparent = true
        textvideo.material.opacity=0
        textweb.material.transparent = true
        textweb.material.opacity=0
        textabout.material.transparent = true
        textabout.material.opacity=0
        textvideo.position.set(2.2,19.7,-30.6)
        textvideo.rotation.set(0,Math.PI*0.87,0)
        textweb.position.set(2.2,21.5,-30.6)
        textweb.rotation.set(0,Math.PI*0.87,0)
        textabout.position.set(-1,21.5,-32)
        textabout.rotation.set(0,Math.PI*0.87,0)
        scene.add(textvideo,textweb,textabout)


    

        const textabout1 = new THREE.TextGeometry(
            'DES SITES WEB',
            {
                font: font,
                size: 0.90,
                height: 0.01,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5,
            }
        )
        const textabout2 = new THREE.TextGeometry(
            'DES VIDEOS',
            {
                font: font,
                size: 0.90,
                height: 0.01,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5,
            }
        )
        const textabout3 = new THREE.TextGeometry(
            'DES MAQUETTES GRAPHIQUES',
            {
                font: font,
                size: 0.90,
                height: 0.01,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5,
            }
        )
        const materialabout1 = new THREE.MeshBasicMaterial()
        const materialabout2 = new THREE.MeshBasicMaterial()
        const materialabout3 = new THREE.MeshBasicMaterial()
        const glicth1 = new THREE.Mesh(textabout1, materialabout1)
        const glicth2 = new THREE.Mesh(textabout2, materialabout2)
        const glicth3 = new THREE.Mesh(textabout3, materialabout3)
        glicth1.material.transparent = true
        glicth1.material.opacity=0
        glicth2.material.transparent = true
        glicth2.material.opacity=0
        glicth3.material.transparent = true
        glicth3.material.opacity=0
        glicth1.position.set(-22.2,31.05,258.4)
        glicth1.rotation.set(0,Math.PI*0.79,0)
        glicth2.position.set(-24,31.20,259)
        glicth2.rotation.set(0,Math.PI*0.78,0)
        glicth3.position.set(-18.9,31.05,261.5)
        glicth3.rotation.set(0,Math.PI*0.78,0)
        scene.add(glicth1,glicth2,glicth3)










/****************************** ANIMATION TEXTES ****************************/
        buttonstart.addEventListener('click', event => {     
            about()
            glitch.volume=0.01;
            gsap.to(textvideo.material, {duration: 0.8, delay: 3.2 ,opacity: 0.8, ease:'expo.inOut'})
            gsap.to(textvideo.material, {duration: 0.4, delay: 4.3 ,opacity: 0.1, ease:'expo.inOut'})
            gsap.to(textvideo.material, {duration: 0.12, delay: 4.7 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(textvideo.material, {duration: 0.2, delay: 4.82 ,opacity: 0.05, ease:'expo.inOut'})
            gsap.to(textvideo.material, {duration: 0.15, delay: 5.02 ,opacity: 0.9, ease:'expo.inOut'})
  
            gsap.to(textweb.material, {duration: 0.6, delay: 3.7 ,opacity: 0.8, ease:'expo.Out'})
            gsap.to(textweb.material, {duration: 0.3, delay: 3.9 ,opacity: 0.05, ease:'expo.inOut'})
            gsap.to(textweb.material, {duration: 0.4, delay: 4.6 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(textweb.material, {duration: 0.12, delay: 8 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(textweb.material, {duration: 0.10, delay: 8.05 ,opacity: 0.4, ease:'expo.inOut'})
            gsap.to(textweb.material, {duration: 0.15, delay: 8.15 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(textweb.material, {duration: 0.10, delay: 8.30 ,opacity: 0.4, ease:'expo.inOut'})
            gsap.to(textweb.material, {duration: 0.15, delay: 8.40 ,opacity: 0.9, ease:'expo.inOut'})
  
            gsap.to(textabout.material, {duration: 0.4, delay: 3.2 ,opacity: 0.8, ease:'expo.Out'})
            gsap.to(textabout.material, {duration: 0.2, delay: 3.6 ,opacity: 0.05, ease:'expo.inOut'})
            gsap.to(textabout.material, {duration: 0.10, delay: 3.8 ,opacity: 0.3, ease:'expo.inOut'})
            gsap.to(textabout.material, {duration: 0.15, delay: 3.9 ,opacity: 0.05, ease:'expo.inOut'})
            gsap.to(textabout.material, {duration: 0.2, delay: 4.05 ,opacity: 0.9, ease:'expo.inOut'})
            setTimeout(function(){ sortienuage=1; loadcine()}, 5000);
          })
  
          window.addEventListener('mousemove', (event) =>
          {
              mouse.x = event.clientX / sizes.width * 2 - 1
              mouse.y = - (event.clientY / sizes.height) * 2 + 1
              
              raycaster.setFromCamera(mouse, camera)
              const found = raycaster.intersectObjects(scene.children)
              var count = Object.keys(found).length;
              if(count<=9 && sortienuage==1){
                  gsap.to(textabout.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  gsap.to(textweb.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  gsap.to(textvideo.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  document.body.style.cursor = 'default'
              }
              if(found.find(element=>element.object.name==='web') && sortienuage==1){
                  gsap.to(textweb.material, {duration: 0.6, delay: 0 ,opacity: 0.4, ease:'expo.Out'})
                  gsap.to(textabout.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  gsap.to(textvideo.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  document.body.style.cursor = 'pointer'
              }
              if(found.find(element=>element.object.name==='video') && sortienuage==1){
                  gsap.to(textvideo.material, {duration: 0.6, delay: 0 ,opacity: 0.4, ease:'expo.Out'})
                  gsap.to(textabout.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  gsap.to(textweb.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  document.body.style.cursor = 'pointer'
              }
              if(found.find(element=>element.object.name==='about') && sortienuage==1){
                  gsap.to(textabout.material, {duration: 0.6, delay: 0 ,opacity: 0.4, ease:'expo.Out'})
                  gsap.to(textweb.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  gsap.to(textvideo.material, {duration: 0.6, delay: 0 ,opacity: 0.9, ease:'expo.Out'})
                  document.body.style.cursor = 'pointer'
              }
              if(found.find(element=>element.object.name==='spa') && sortienuage==1 ){
                document.body.style.cursor = 'pointer'
            }
            if(found.find(element=>element.object.name==='champagne') && sortienuage==1 ){
                document.body.style.cursor = 'pointer'
            }
            if(found.find(element=>element.object.name==='halloween') && sortienuage==1 ){
                document.body.style.cursor = 'pointer'
            }
          })

         function about(){
            glitch.play()
            gsap.to(glicth3.material, {duration: 0.10, delay: 0 ,opacity: 0.08, ease:'expo.inOut'})
            gsap.to(glicth3.material, {duration: 0.12, delay: 0.35 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(glicth3.material, {duration: 0.18, delay: 0.47 ,opacity: 0, ease:'expo.Out'})
            gsap.to(glicth1.material, {duration: 0.2, delay: 0.66 ,opacity: 0.8, ease:'expo.inOut'})
            gsap.to(glicth1.material, {duration: 0.15, delay: 0.86 ,opacity: 0.1, ease:'expo.inOut'})
            gsap.to(glicth1.material, {duration: 0.12, delay: 1.01 ,opacity: 0.9, ease:'expo.inOut'})
            
            gsap.to(glicth1.material, {duration: 0.2, delay: 4 ,opacity: 0.08, ease:'expo.inOut'})
            gsap.to(glicth1.material, {duration: 0.15, delay: 4.2 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(glicth1.material, {duration: 0.18, delay: 4.35 ,opacity: 0, ease:'expo.Out'})
            gsap.to(glicth2.material, {duration: 0.15, delay: 4.73 ,opacity: 0.8, ease:'expo.inOut'})     
            gsap.to(glicth2.material, {duration: 0.10, delay: 4.88 ,opacity: 0.1, ease:'expo.inOut'})
            gsap.to(glicth2.material, {duration: 0.12, delay: 4.98 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(glicth2.material, {duration: 0.15, delay: 6.73 ,opacity: 0.8, ease:'expo.inOut'})     
            gsap.to(glicth2.material, {duration: 0.10, delay: 6.88 ,opacity: 0.1, ease:'expo.inOut'})
            gsap.to(glicth2.material, {duration: 0.20, delay: 7 ,opacity: 0.9, ease:'expo.inOut'})

            gsap.to(glicth2.material, {duration: 0.2, delay: 8 ,opacity: 0.08, ease:'expo.inOut'})
            gsap.to(glicth2.material, {duration: 0.15, delay: 8.2 ,opacity: 0.9, ease:'expo.inOut'})
            gsap.to(glicth2.material, {duration: 0.18, delay: 8.35 ,opacity: 0, ease:'expo.Out'})
            gsap.to(glicth3.material, {duration: 0.15, delay: 8.53 ,opacity: 0.8, ease:'expo.inOut'})     
            gsap.to(glicth3.material, {duration: 0.2, delay: 8.68 ,opacity: 0.1, ease:'expo.inOut'})
            gsap.to(glicth3.material, {duration: 0.12, delay: 8.88 ,opacity: 0.9, ease:'expo.inOut'})
            setTimeout(function(){ about()}, 13000);
         } 
     }
 )















/****************************** FORMES 3D ****************************/
 const  material = new  THREE.MeshBasicMaterial({
    color:0x000000,
    wireframe: true,
    side:THREE.DoubleSide,
})
const curvevideo = new THREE.CatmullRomCurve3( [
    new THREE.Vector3( -2, 20, -65 ),
    new THREE.Vector3( -2, 20, -60 ),
    new THREE.Vector3( 20, 10, -24 ),
    new THREE.Vector3( 16, 5.5, 36 ),
    new THREE.Vector3( 10, 6, 46 ),
    new THREE.Vector3( -3.8, 4.9, 60 ),
    new THREE.Vector3( -5.2, 4.9, 104 ),
] );
const geometryvideo = new THREE.TubeBufferGeometry( curvevideo, 100, 2, 8, true );
const  tubevideo = new THREE.Mesh(geometryvideo,material);
tubevideo.visible= false;
scene.add(tubevideo);



const curveweb = new THREE.CatmullRomCurve3( [
    new THREE.Vector3( -2, 20, -65 ),
    new THREE.Vector3( -4, 20, -55 ),
    new THREE.Vector3( -25, 26, 0 ),
    new THREE.Vector3( -24, 29, 60 ),
    new THREE.Vector3( -100, 38, 100 ),
    new THREE.Vector3( -85, 56, 165 ),
    new THREE.Vector3( -65, 55.5, 160 ),

] );
const geometryweb = new THREE.TubeBufferGeometry( curveweb, 100, 2, 8, true );
const  tubeweb = new THREE.Mesh(geometryweb,material);
tubeweb.visible= false;
scene.add(tubeweb);



const curveabout = new THREE.CatmullRomCurve3( [
    new THREE.Vector3( -2, 20, -65 ),
    new THREE.Vector3( -4, 20, -55 ),
    new THREE.Vector3( -25, 27, 10 ),
    new THREE.Vector3( 80, 8, 120 ),
    new THREE.Vector3( 0, 27, 240 ),
] );
const geometryabout = new THREE.TubeBufferGeometry( curveabout, 200, 2, 8, true );
const  tubeabout = new THREE.Mesh(geometryabout,material);
tubeabout.visible= false;
scene.add(tubeabout);



const geometryhitboxweb = new THREE.BoxGeometry(3,1.5,0.2)
const  hitboxweb = new THREE.Mesh(geometryhitboxweb,material);
hitboxweb.position.set(0.9,22,-31.4)
hitboxweb.rotation.set(0,Math.PI*0.87,0)
hitboxweb.name = "web"
hitboxweb.visible= false;
scene.add(hitboxweb);

const geometryhitboxvideo = new THREE.BoxGeometry(3.7,1.5,1)
const  hitboxvideo = new THREE.Mesh(geometryhitboxvideo,material);
hitboxvideo.position.set(0.6,20.2,-32)
hitboxvideo.rotation.set(0,Math.PI*0.87,0)
hitboxvideo.name = "video"
hitboxvideo.visible= false;
scene.add(hitboxvideo,);

const geometryhitboxabout = new THREE.BoxGeometry(4,1.5,0.2)
const  hitboxabout = new THREE.Mesh(geometryhitboxabout,material);
hitboxabout.position.set(-2.8,22,-33.2)
hitboxabout.rotation.set(0,Math.PI*0.87,0)
hitboxabout.name = "about"
hitboxabout.visible= false;
scene.add(hitboxabout);


















/****************************** AFFICHAGE ****************************/
 const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
})


const cameraGroup = new THREE.Group()
scene.add(cameraGroup)
const cursor = {}
cursor.x = 0
cursor.y = 0
window.addEventListener('mousemove', (event) =>{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})
const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)
camera.position.set(-10, 30, -500)
camera.updateProjectionMatrix();
cameraGroup.add(camera) 


const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    powerPreference: 'high-performance'
})
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = false
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
document.body.appendChild( renderer.domElement );














/****************************** SUIVI FOCUS ****************************/
 const raycaster = new THREE.Raycaster()
 const mouse = new THREE.Vector2()
 gsap.registerPlugin(CustomEase);
 window.addEventListener('click', () =>{
    mouse.x = (event.clientX / sizes.width) * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1
    
    raycaster.setFromCamera(mouse, camera)
    const found = raycaster.intersectObjects(scene.children)
    if(found.find(element=>element.object.name==='web') && sortienuage==1 ){
        DepartCameraweb()
        const tl = gsap.timeline({repeat: 0});
        tl.to(mvmweb, {duration: 10 ,value: 0.98, delay:1, ease: CustomEase.create("custom", "M0,0 C0.23,0.014 0.386,0.314 0.5,0.5 0.644,0.736 0.734,1 1,1 "), onUpdate: function() {updateCameraweb()},});
        tl.to(minview, {duration: 3, delay: 8 ,value: 20,  onUpdate: function() {
            scene.fog = new THREE.Fog(fogColor, minview.value, maxview.value);
          },
        }, "<" );
    }
    if(found.find(element=>element.object.name==='video') && sortienuage==1 ){    
        DepartCameravideo()
        const tl = gsap.timeline({repeat: 0});
        tl.to(mvmvideo, {duration: 10 ,value: 0.60, delay:1, ease: CustomEase.create("custom", "M0,0 C0.2,0 0.236,0.255 0.292,0.494 0.397,0.95 0.504,1 1,1 "), onUpdate: function() {updateCameravideo()},});    
        tl.to(maxview, {duration: 10 ,value: 110,  onUpdate: function() {
            scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
            const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)},
        }, "<" );
    }
    if(found.find(element=>element.object.name==='about') && sortienuage==1 ){
        DepartCameraabout()
        const tl = gsap.timeline({repeat: 0});
        tl.to(mvmabout, {duration: 10 ,value: 0.94, delay:1, ease: CustomEase.create("custom", "M0,0 C0.23,0.014 0.386,0.314 0.5,0.5 0.644,0.736 0.734,1 1,1 "), onUpdate: function() {updateCameraabout()},});
        tl.to(maxview, {duration: 3 , delay:8, value: 150,  onUpdate: function() {
            scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
            const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)},
        }, "<" );
    }
    if(found.find(element=>element.object.name==='spa') && sortienuage==1 ){
        window.open('https://passionspasgrand-est.com/', '_blank');
    }
    if(found.find(element=>element.object.name==='champagne') && sortienuage==1 ){
        window.open('https://champagne-varain-pougalan.fr/', '_blank');
    }
    if(found.find(element=>element.object.name==='halloween') && sortienuage==1 ){
        window.open('https://mmi20g13.mmi-troyes.fr/halloween/', '_blank');
    }
 })
 












 /****************************** MOUVEMENTS CAMERA ****************************/
    function DepartCameravideo(){
        noneanimation=1
        lookatdepart=1;
        travel=1;
        gsap.to(lookcamx, { duration: 1, value:0.7806754188040212, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        gsap.to(lookcamy, { duration: 1, value:18.81850976627614, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        gsap.to(lookcamz, { duration: 1, value:-54.89259156645889, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        setTimeout(function(){ travel=0; }, 10000);
        
    }
    function updateCameravideo() {
       const  posvideo = tubevideo.geometry.parameters.path.getPointAt(mvmvideo.value);
       const  posvideo2 =   tubevideo.geometry.parameters.path.getPointAt(mvmvideo.value+0.06);
        camera.position.copy(posvideo);
        camera.lookAt(posvideo2);
    }
    
    function DepartCameraweb(){
        noneanimation=1
        lookatdepart=1;
        travel=1;
        gsap.to(lookcamx, { duration: 1, value:-3.048444616407632, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        gsap.to(lookcamy, { duration: 1, value:19.89176323237025, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        gsap.to(lookcamz, { duration: 1, value:-58.85580385438058, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        setTimeout(function(){  noneanimation=0; travel=0; }, 11000);
    }
    function updateCameraweb() {
        const  posweb = tubeweb.geometry.parameters.path.getPointAt(mvmweb.value);
        const  posweb2 =   tubeweb.geometry.parameters.path.getPointAt(mvmweb.value+0.02);
        camera.position.copy(posweb);
        camera.lookAt(posweb2);
     }

     function DepartCameraabout(){
        noneanimation=1
        lookatdepart=1;
        travel=1;
        gsap.to(lookcamx, { duration: 1, value:-3.611312392170582, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        gsap.to(lookcamy, { duration: 1, value:19.922832901077445, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        gsap.to(lookcamz, { duration: 1, value:-56.32610124776667, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
        setTimeout(function(){glitch.volume=0.8}, 9000);
        setTimeout(function(){  noneanimation=0; travel=0; }, 11000);
    }
    function updateCameraabout() {
        const  posabout = tubeabout.geometry.parameters.path.getPointAt(mvmabout.value);
        const  posabout2 =   tubeabout.geometry.parameters.path.getPointAt(mvmabout.value+0.02);
        camera.position.copy(posabout);
        camera.lookAt(posabout2);
     }
    function updatemoovie(){
        camera.lookAt(lookcamx.value,lookcamy.value,lookcamz.value);
    }













/****************************** NAVIGATION ****************************/
 buttonstart.addEventListener('click', event => {
    gsap.to(camera.position, { duration: 5.67,  x: -2, y: 20, z: -65, ease: 'expo.inOut' })
    gsap.to(maxview, {duration: 3.67, delay: 3 ,value: 200,  onUpdate: function() {
      scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
      const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)
    },
  });
     audio.play()
     audiob.play()
     neon.volume=0.6;
     audiob.volume=0.01;
     distortion.volume=0.16;
     distortion_entre.volume=0.16;
     buttonstart.style.margin='1vh 2vh'
     setTimeout(function(){ neon.play() }, 2550);
     setTimeout(function(){buttonstart.classList.add("open");}, 600);
     document.querySelector('#s-control1').classList.add("soundon");
     setTimeout(function(){document.querySelector('#s-control2').classList.add("soundon");}, 200);
     setTimeout(function(){document.querySelector('#s-control3').classList.add("soundon");}, 400);
     setTimeout(function(){
        buttonstart.style.display='none';
        barre.style.display='none';
     }, 3200);
});

buttonback.addEventListener('click', event => {
    if(chantier===1){
        setTimeout(function(){woosh.play();}, 930);
        gsap.to(camera.position, { duration: 4 , x: 12, y: 25, z: 220, ease: 'expo.inOut' });
        gsap.to(maxview, {duration: 4,delay:1, value: 150,  onUpdate: function() {
            scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
            const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)},
        });
        setTimeout(function(){chantier=0;}, 4000);
    }
    if(sallecine===1 && travel===0){
        setTimeout(function(){woosh.play();}, 130);
        setTimeout(() => {video.pause();}, 1500);
        gsap.to(video, {duration: 1, delay: 0 ,volume: 0 });
        gsap.to(audio, {duration: 2, delay: 0.5 ,volume: 1 });  
        DepartCameravideo()
        const tl = gsap.timeline({repeat: 0});
        tl.to(mvmvideo, {duration: 4 ,value: 0.60, ease: CustomEase.create("custom", "M0,0 C0.2,0 0.236,0.255 0.292,0.494 0.397,0.95 0.504,1 1,1 "), onUpdate: function() {updateCameravideo()},});    
        tl.to(maxview, {duration: 4 ,value: 110,  onUpdate: function() {
            scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
            const camera = new THREE.PerspectiveCamera(36, sizes.width / sizes.height, 0.1, maxview.value)},
        }, "<" );
        setTimeout(function(){sallecine=0;travel=0;}, 4000);
    }
    else if(chantier===0 && sallecine===0 && travel===0){
    distortion_entre.play();
    glitch.volume=0.01;
    minview.value=0;
    gsap.to(audio, {duration: 1.6, delay: 0 ,volume: 0 });
    gsap.to(video, {duration: 1, delay: 0 ,volume: 0 });
    gsap.to(maxview, {duration: 1.2, delay: 0 ,value: 0,  onUpdate: function() {
        scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
    },});
    gsap.to(audiob, {duration: 1.6, delay: 0 ,volume: 1 });  
    gsap.to(audio, {duration: 1.5, delay: 2.6 ,volume: 1 });
    gsap.to(maxview, {duration: 3, delay: 2.5 ,value: 200,  onUpdate: function() {
        scene.fog = new THREE.Fog(fogColor, 0, maxview.value);
    },});
    gsap.to(audiob, {duration: 1.5, delay: 2.6 ,volume: 0 }); 
    gsap.to(camera.position, { duration: 0.01, delay:1.9 , x: -2, y: 20, z: -45, ease: 'expo.inOut' })
    gsap.to(camera.position, { duration: 2, delay:2 , x: -2, y: 20, z: -65, ease: 'expo.inOut' })
    gsap.to(lookcamx, { duration: 0.01, delay:2 ,value:0, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
    gsap.to(lookcamy, { duration: 0.01, delay:2 ,value:13, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
    gsap.to(lookcamz, { duration: 0.01, delay:2 ,value:180, ease:'expo.inOut', onUpdate: function() {updatemoovie()},})
    function updatemoovie(){
        camera.lookAt(lookcamx.value,lookcamy.value,lookcamz.value);
      }
    setTimeout(function(){document.querySelector('#background-color').style.display='inline'; }, 1900);
     setTimeout(function(){distortion.play(), mvmvideo.value=0, mvmweb.value=0, mvmabout.value=0, video.load()}, 2000);
     setTimeout(function(){document.querySelector('#background-color').style.display='none';}, 2100);
    }
});



   

controlsound.addEventListener('click', event => {
    if(activesound==1){
        document.querySelectorAll("video").forEach( video => video.muted=true );
        document.querySelectorAll("audio").forEach( audio => audio.muted=true );  
        document.querySelector('#s-control1').classList.remove("soundon");
        setTimeout(function(){document.querySelector('#s-control2').classList.remove("soundon");}, 200);
        setTimeout(function(){document.querySelector('#s-control3').classList.remove("soundon");}, 400);
        setTimeout(function(){activesound=0;}, 40);
    }
    if(activesound==0){
        document.querySelectorAll("video").forEach( video => video.muted=false );
        document.querySelectorAll("audio").forEach( audio => audio.muted=false );  
        document.querySelector('#s-control1').classList.add("soundon");
        setTimeout(function(){document.querySelector('#s-control2').classList.add("soundon");}, 200);
        setTimeout(function(){document.querySelector('#s-control3').classList.add("soundon");}, 400);      
        setTimeout(function(){activesound=1;}, 40);
    }
})












/****************************** REPETITION ****************************/
const clock = new THREE.Clock()
let previousTime =0
const tick = () => {

    if(sizes.height > sizes.width){
        orientation.style.display='flex';
    }
    if(sizes.height < sizes.width){
        orientation.style.display='none';
    }

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    if(noneanimation===0){
        const parallaxX = cursor.x 
        const parallaxY = cursor.y 
        poscamx=(parallaxX - cameraGroup.position.x) * 5 * deltaTime
        poscamy=(parallaxY - cameraGroup.position.y) * 5 * deltaTime
        cameraGroup.position.x += poscamx
        cameraGroup.position.y += poscamy
    }
    if(lookatdepart===0){
    camera.lookAt(lookcamx.value,lookcamy.value,lookcamz.value)
    }
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}
tick()
